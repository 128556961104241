import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SidebarLogin from '../components/SidebarLogins';
import Wrapper from '../components/Wrapper';

const FinancialPlanning = ({ data }) => (
  <Layout>
    <Wrapper>
      <SEO title="Financial Planning" />
      <article className="max-w-2xl mx-auto w-full">
        <div className="max-w-md pt-4 px-4">
          <Link to="/financial-planning/">
            <h1 className="text-5xl">Financial Planning</h1>
          </Link>
          <hr />
        </div>
        <div className="flex flex-wrap">
          <div className="max-w-md pb-4 px-4 w-full">
            <h2>Self-managed Superannuation Funds</h2>
            <p>
              SMSFs a wonderful thing to say you have at a BBQ with friends, but
              get it wrong and you can lose so much. It is a great vehicle to
              reduce tax, it’s a great way to plan for your retirement, and
              great for control. BUT it must be done right.
            </p>

            <h2>Superannuation</h2>
            <p>
              As an employer or employee superannuation is now a heavily
              regulated area of your business and an unexpected cost that can
              put a lot of pressure on your business. Or as an employee a great
              tool to reduce your taxable income and pay less tax.
            </p>

            <h2>Trusts</h2>
            <p>
              Protection and flexibility, a trust is a vehicle you can use to
              run your business, distribute income to owners and family members
              and maximise your entire families taxation. There are not a lot of
              downsides to trusts and something that can really improve your net
              wealth.
            </p>

            <h2>Rental Property</h2>
            <p>
              A rental property is an investment in your future and a long term
              strategy, it's great to lose thousands each year to save say 30%
              in tax or is it? If the property is going up in capital value,
              then you are making money, and if you do it right and sell when
              your income is not high you’re effectively deferring and
              minimising your tax, but there are cash flow issues. It’s more
              than picking a house and renting it out, we have some great
              strategies to help you make the right decisions.
            </p>
          </div>
          <div className="flex-1 pb-4 px-4 w-full lg:w-1/4">
            <div className="lg:ml-24">
              <h2>Logins</h2>
              <SidebarLogin />
              <Img fluid={data.imagePlanning.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </article>
    </Wrapper>
  </Layout>
);

FinancialPlanning.propTypes = {
  data: PropTypes.object,
};

export default FinancialPlanning;

export const pageQuery = graphql`
  query {
    imagePlanning: file(relativePath: { eq: "financial-planning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
