import PropTypes from 'prop-types';
import React from 'react';

const Wrapper = ({ children }) => (
  <main className="skew bg-white flex flex-col flex-1 md:justify-start w-full">
    <div className="unskew py-8">{children}</div>
  </main>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrapper;
